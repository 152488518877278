import axios from '@axios'
import baseConfig from '@/config/index'
const TICKET_SERVICE_PATH = baseConfig.BASE_PATH
const TICKET_API_SERVICE_PATH = baseConfig.TICKET_API_SERVICE_PATH

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchRefProducts(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refProduct/list', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefProductsWithChildren(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refProduct/listWithChildren', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchRefProduct(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refProduct/detail', { refProductId: id})
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editRefProduct(ctx, refProductData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refProduct/edit', refProductData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteRefProduct(ctx, refProductData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refProduct/delete', refProductData)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addRefProduct(ctx, refProductData) {
      return new Promise((resolve, reject) => {
        axios
          .post(TICKET_API_SERVICE_PATH + '/refProduct/create', refProductData )
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
